import { map } from 'rxjs';

import { Injectable, inject } from '@angular/core';
import { ThemeClasses, PrismaValidation as Validation } from '@beathletics/api-interfaces';

import { RouterStateService } from '../router-state.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeColorService {
  private routerService = inject(RouterStateService);

  colors: { [theme: string]: ThemeClasses } = {
    athlete: {
      themeId: 'athlete',
      svgId: 'athlete',
      fill: 'svg-fill athlete',
      background: 'bg-color athlete',
      font: 'font-color athlete',
      border: 'borders athlete',
    },
    event: {
      themeId: 'event',
      svgId: 'event',
      fill: 'svg-fill event',
      background: 'bg-color event',
      font: 'font-color event',
      font_colored: 'font-color event colored',
      border: 'borders event',
    },
    material: {
      themeId: 'material',
      svgId: 'home',
      fill: 'svg-fill material',
      background: 'bg-color material',
      font: 'font-color material',
      border: 'borders material',
    },
    home: {
      themeId: 'home',
      svgId: 'home',
      fill: 'svg-fill material',
      background: 'bg-color material',
      font: 'font-color material',
      border: 'borders material',
    },
    calendar: {
      themeId: 'athlete',
      svgId: 'event',
      fill: 'svg-fill material',
      background: 'bg-color material',
      font: 'font-color athlete',
      border: 'borders material',
    },
  };

  theme_color$ = this.routerService.data$.pipe(
    map((d) => d.theme),
    map((theme) => {
      theme ??= 'material';
      return this.colors[theme];
    }),
  );

  validationColor: { [key in Validation]: string } = {
    [Validation.pending]: '#ffffff',
    [Validation.ja_approved]: '#93c5fd',
    [Validation.ja_not_approved]: '#ffd339',
    [Validation.h_certified]: '#86efac',
    [Validation.h_not_certified]: '#ff6060',
  };
}
